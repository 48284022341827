<template>
  <div>
    <b-card title="Purple MDM 🚀">
      <b-card-text>
        Provision, manage and check the compliance of all your devices.
        Please make sure to read our <b-link href="#" target="_blank">FAQ</b-link> to understand some of the
        concepts and terminology used in our MDM solution.
      </b-card-text>
    </b-card>

    <b-overlay
      :show="loading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      varient="white"
    >

      <b-row>
        <b-col xl="12">
          <b-card no-body title="Device information and Security 🔒">
            <b-card-header>
              <b-card-title>Device information and Security 🔒</b-card-title>
              <b-card-text class="font-small-2 mr-25 mb-0">
                Updated: 1 min ago
              </b-card-text>
            </b-card-header>
            <b-card-body>
              <b-card-text v-if="!loading">
                <span v-if="deviceStats.unsecure > 0">
                  There are currently
                  <router-link :to="{ path: 'devices?secure=false', params: { secure: false } }" class="font-weight-bold text-nowrap">
                    {{ deviceStats.unsecure }} devices
                  </router-link>
                  registered which are unsecure.
                </span>
                <span v-if="deviceStats.unsecure === 0">
                  There are currently no devices which have any security issues 🎉
                </span>
              </b-card-text>
            </b-card-body>
            <b-card-body class="statistics-body">
              <b-row>

                <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                  <router-link :to="{ path: 'devices' }" class="font-weight-bold text-nowrap">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="TabletIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ deviceStats.total }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Devices
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </router-link>
                </b-col>

                <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="LockIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ devicesSecure }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ 'Devices Secure: ' + deviceStats.secure }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>

                <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-info">
                        <feather-icon size="24" icon="SearchIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ devicesEncrypted }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ 'Devices Encrypted: ' + deviceStats.encrypted }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>

                <b-col xl="3" sm="6" class="">
                  <router-link :to="{ path: 'devices?secure=false' }" class="font-weight-bold text-nowrap">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="light-danger">
                          <feather-icon size="24" icon="AlertOctagonIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ deviceStats.unsecure }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Issues Found
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </router-link>
                </b-col>
              </b-row>
            </b-card-body>

          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="$can('Read', 'Owner')" class="match-height">
        <b-col md="6" sm="12">
          <top-activities />
        </b-col>
        <b-col md="6" sm="12">
          <activity-count />
        </b-col>
      </b-row>

    </b-overlay>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import DeviceService from '@/services/DeviceService';
import helperService from '@/services/HelperService';
import TopActivities from '@/components/charts/TopActivities.vue';
import ActivityCount from '@/components/charts/ActivityCount.vue';

export default {
  components: {
    TopActivities,
    ActivityCount,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      deviceStats: {
        total: '-',
        encrypted: 0,
        unencrypted: 0,
        secure: 0,
        unsecure: 0,
      },
      loading: true,
    }
  },
  computed: {
    // a computed getter
    devicesSecure() {
      // `this` points to the vm instance
      try {
        if (this.deviceStats.total === '-') {
          return '-'
        }
        if (this.deviceStats.total === 0) {
          return '100%';
        }
        return `${((this.deviceStats.secure / this.deviceStats.total) * 100).toFixed(0)}%`;
      } catch (err) {
        return '...';
      }
    },
    devicesEncrypted() {
      // `this` points to the vm instance
      try {
        if (this.deviceStats.total === '-') {
          return '-'
        }
        if (this.deviceStats.total === 0) {
          return '100%';
        }
        return `${((this.deviceStats.encrypted / this.deviceStats.total) * 100).toFixed(0)}%`;
      } catch (err) {
        return '...';
      }
    },
  },
  mounted() {
    // If they can read the devices, load the data
    this.getDeviceStats()
  },
  methods: {
    async getDeviceStats() {
      this.loading = true
      DeviceService.stats().then(res => {
        this.deviceStats = res.data;
      }).catch(err => {
        helperService.showNotfyErr(err, 'Could not get devices, please refresh and try again')
      }).finally(() => {
        this.loading = false
      });
    },
  },
}
</script>

<style>

</style>
